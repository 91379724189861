import type RouterStore from 'src/stores/router-store';
import type { Route } from 'src/types/proto/routes';

interface NavigateOptions {
  route?: Route;
  path?: string;
  noBack?: boolean;
  search?: Record<string, unknown>;
  replace?: boolean;
  f?: unknown;
}

export default function navigateToFlow(
  router: RouterStore,
  flowId: string,
  options: NavigateOptions = {}
) {
  const route = options.route || (router.route as Route);
  return router.navigatePromise(
    'flow.page',
    {
      flowId,
      path: options.path || 'root',
      back: options.noBack
        ? undefined
        : JSON.stringify({
            name: route.name,
            params: route.params,
          }),
      ...options.search,
    },
    {
      replace: options.replace,
    }
  );
}

export function flowRoute(
  router: RouterStore,
  flowId: string,
  options: NavigateOptions = {}
) {
  const route = options.route || (router.route as Route);
  return {
    name: 'flow.page',
    params: {
      flowId,
      path: options.path || 'root',
      back: options.noBack
        ? undefined
        : JSON.stringify({
            name: route.name,
            params: route.params,
          }),
      f: options.f,
      ...options.search,
    },
  };
}
