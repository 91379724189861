

import FakeProgress from 'src/components/common/fake-progess';
import React from 'react';
import classNames from 'classnames';
import {
  ExclamationCircleOutlined,
  FileTwoTone,
  LoadingOutlined,
} from '@ant-design/icons';
import { Progress, Spin, notification } from 'antd';
import { getColor } from 'src/utils/get-color';
import { Provider } from 'mobx-react';

// apporicmate time to upload a file, used to calculate
// speed of progress bar.
const FILE_UPLOAD_TIME = 20000; // 20 seconds

export const FILE_NOTIFICATION_KEY = 'file_upload_nofitications';
export const END_TIME = 0;
export const FAILED_TIME = -1;
export const INVALID_PDF = -2;
export const ZIPFORM_FAILURE = -3;
export const DOCUSIGN_FAILURE = -4;
export const ZIPFORM_BLANK_DOCUMENT_FAILURE = -5;
export const CONVERSION_ERROR = -6;

const clsPrefix = 'app-uploads';

function getFailureReason(code) {
  let reason = '';
  if (code === INVALID_PDF) {
    reason = 'Invalid PDF, please check your file and try again';
  }
  if (code === ZIPFORM_FAILURE) {
    reason = 'Failed to download file from zipform. Please retry again.';
  }
  if (code === DOCUSIGN_FAILURE) {
    reason = 'Failed to download file from docusign.';
  }
  if (code === ZIPFORM_BLANK_DOCUMENT_FAILURE) {
    reason = "Can't download empty form from zipForm.";
  }
  if (code === CONVERSION_ERROR) {
    reason = 'Unrecognized file format';
  }
  return reason ? `: ${reason}` : '';
}

function getProcessingSuffix(fileName, asPDF) {
  const isPDF = fileName.match(/pdf/i);

  if (!isPDF && asPDF) {
    return '(will be converted to PDF)';
  }
  return '';
}

function getDescription(docs, uploadProgress) {
  return (
    <div className={`${clsPrefix}__description`}>
      {[...docs.keys()].map((fileName) => {
        const startTime = docs.get(fileName).status;
        const asPDF = !!docs.get(fileName).asPDF;
        const isSuccessful = startTime === END_TIME;
        const didFail = startTime && startTime < END_TIME;
        const isComplete = isSuccessful || didFail;
        const twoToneColor = isComplete
          ? getColor('app-blue')
          : getColor('gray-color');

        return (
          <div key={fileName} className={`${clsPrefix}__item`}>
            {didFail ? (
              <ExclamationCircleOutlined
                className={`${clsPrefix}__failed-icon`}
              />
            ) : (
              <FileTwoTone
                className={`${clsPrefix}__icon`}
                twoToneColor={twoToneColor}
              />
            )}
            <div className={`${clsPrefix}__filename-wrap`}>
              <div
                className={classNames(`${clsPrefix}__filename`, {
                  [`${clsPrefix}__filename--incomplete`]: !isComplete,
                  [`${clsPrefix}__filename--failed`]: didFail,
                })}
              >
                {fileName}
                {didFail ? ` ${getFailureReason(startTime)}` : ''}
                {!isComplete ? ` ${getProcessingSuffix(fileName, asPDF)}` : ''}
              </div>
              {!isComplete && uploadProgress === false && (
                <FakeProgress
                  startTime={startTime}
                  estimatedTime={FILE_UPLOAD_TIME}
                  isComplete={isComplete}
                  showInfo={false}
                  strokeWidth={2}
                  strokeLinecap="square"
                />
              )}
              {!isComplete && uploadProgress !== false && (
                <Progress
                  showInfo={false}
                  strokeWidth={2}
                  strokeLinecap="square"
                  percent={uploadProgress}
                />
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
}

function getMessage(allComplete) {
  return (
    <div className={`${clsPrefix}__title-wrap`}>
      <div className={`${clsPrefix}__title`}>
        <strong> Uploading documents</strong>
      </div>
      {!allComplete && (
        <Spin
          className={`${clsPrefix}__spin`}
          indicator={<LoadingOutlined spin />}
        />
      )}
    </div>
  );
}

export default function showDocumentNotification(
  docs,
  allComplete,
  onClose,
  progress,
  ui,
  features
) {
  const message = (
    <Provider ui={ui} features={features}>
      {getMessage(allComplete)}
    </Provider>
  );
  const description = (
    <Provider ui={ui} features={features}>
      {getDescription(docs, progress)}
    </Provider>
  );
  const className = classNames(clsPrefix, {
    [`${clsPrefix}__hide-close`]: !allComplete,
  });

  notification.open({
    className,
    key: FILE_NOTIFICATION_KEY,
    message,
    description,
    placement: 'topRight',
    onClose,
  });
}
