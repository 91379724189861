

import React, { FC } from 'react';
import classNames from 'classnames';
import poweredByGlideIcon from 'src/images/powered-by-glide.svg';

const clsPrefix = 'app-powered-by-glide';

interface PoweredByGlideProps {
  className?: string;
}

const PoweredByGlide: FC<PoweredByGlideProps> = (props) => {
  const { className } = props;
  return (
    <div className={classNames(clsPrefix, className)}>
      <img src={poweredByGlideIcon} alt="" />
    </div>
  );
};

export default PoweredByGlide;
