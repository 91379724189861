import axios, { type AxiosResponse } from 'axios';
import { saveAs } from 'file-saver';
import multiDownload from 'multi-download';
import URI from 'urijs';
import type {
  ForwardSharedPacketRequest,
  GetSharedPacketDiffPreviewResponse,
  GetSharedPacketDocumentUrlResponse,
  RevokeAccessResponse,
} from 'src/types/proto/services/packet_public_service';
import type {
  SharedSharePacketView,
  SharePacketActivitiesView,
} from 'src/types/proto/shared_item_view_models';
import type { ApiRequestBody } from 'src/types/utils';
import BaseApi from '../base-api';

/* TODO: find a better place for these fileservice related functions  */
const FILESERVICE_URL = `${window.Glide.FILESERVICE}/zip:onthefly` as const;

export interface FileServiceZipFileItem {
  name: string;
  url: string;
  dir?: string;
}

export interface FileServiceInputFileItem extends FileServiceZipFileItem {
  displayName?: string;
}

export interface FileServiceZipOptions {
  numberDocuments?: boolean;
}

export interface FileServiceDownloadZip {
  (
    files: FileServiceInputFileItem[],
    outputFilename: string,
    options?: FileServiceZipOptions
  ): Promise<void>;
}

const fileServicePostDownload: FileServiceDownloadZip = async (
  files,
  outputFilename,
  options
) => {
  const response: AxiosResponse<ArrayBuffer> = await axios({
    method: 'post',
    url: FILESERVICE_URL,
    data: {
      files,
      number_documents: !!options?.numberDocuments,
    },
    responseType: 'arraybuffer',
  });
  saveAs(
    new Blob([response.data], {
      type: 'application/zip',
    }),
    outputFilename
  );
};

export const fileServiceDownloadZip: FileServiceDownloadZip = async (
  files,
  outputFilename,
  options
) => {
  const toDownload = files.map((f) => {
    const docName = f.name || f.displayName || '';
    return {
      url: f.url,
      name: docName.match(/\.pdf$/i) ? docName : `${docName}.pdf`,
      dir: f.dir,
    };
  });

  const uri = URI(FILESERVICE_URL).query({
    files: JSON.stringify(toDownload),
    output: outputFilename,
    number_documents: !!options?.numberDocuments,
  });
  /* AWS only accepts requests with a header size of at most 8kb, as an
     empiric calculation, leaving 2kb for the rest of the header, send as GET
     if the uri is at most 6kb long */
  if (uri.toString().length > 6 * 1024) {
    return fileServicePostDownload(toDownload, outputFilename, options);
  }
  return multiDownload([uri]);
};

export default class SharedPacket extends BaseApi {
  getPacket(sharedItemUUid: string, activity?: boolean) {
    return this.get<SharedSharePacketView>(
      `/shared/share_packet/${sharedItemUUid}`,
      {
        params: {
          activity,
          r: Math.random(),
        },
      }
    );
  }

  revokeAccess(sharedItemUUid: string) {
    return this.post<RevokeAccessResponse>(
      `/shared/share_packet/${sharedItemUUid}/revoke_access`
    );
  }

  getActivitiesView(sharePacketId: string) {
    return this.get<SharePacketActivitiesView>(
      `/shared/share_packet_activities_view/${sharePacketId}`,
      {
        params: {
          r: Math.random(),
        },
      }
    );
  }

  getDiffPreview(sharePacketId: string) {
    return this.get<GetSharedPacketDiffPreviewResponse>(
      `/shared/share_packet/${sharePacketId}/diff_preview`,
      {
        params: {
          r: Math.random(),
        },
      }
    );
  }

  downloadDocumentURL(documentId: string, uuid: string) {
    return this.get<GetSharedPacketDocumentUrlResponse>(
      `/shared/share_packet/${uuid}/documents/${documentId}/url`
    );
  }

  getDownloadAllForShareItem(uuid: string) {
    return this.get<void>(`/shared/share_item/${uuid}/all_documents`);
  }

  fileServiceDownloadZip: FileServiceDownloadZip = (...params) =>
    fileServiceDownloadZip(...params);

  forwardSharePacket(
    uuid: string,
    data: ApiRequestBody<ForwardSharedPacketRequest>
  ) {
    return this.post<void>(
      `/shared/share_packet/${uuid}/forward_share_packet`,
      data
    );
  }
}
