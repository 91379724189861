import LocalStorage from 'lowdb/adapters/LocalStorage';
import invariant from 'invariant';
import low from 'lowdb';

let storage: ReturnType<typeof low>;

export default function getLocalStorage() {
  if (!storage) {
    invariant(
      !window.Glide?.isEmbedded,
      'Cannot use localStorage on Glide embedded.'
    );
    const adapter = new LocalStorage('db');
    storage = low(adapter);
  }

  return storage;
}
