
import find from 'lodash/find';
import flatten from 'lodash/flatten';
import uniq from 'lodash/uniq';
import { action, computed, makeObservable, observable } from 'mobx';
import { recombineTransactionDocuments } from 'src/models/transactions/intents';
import {
  DEFAULT_ZOOM_LEVEL,
  MAX_ZOOM_LEVEL,
  MIN_ZOOM_LEVEL,
  TILE_SPACING,
} from './constants';

function sortPagesInOrder(pages, order) {
  return pages.sort((p1, p2) => order.indexOf(p1) - order.indexOf(p2));
}

export default class DocumentEditState {
  @observable order = [];
  @observable pages = [];
  @observable selectedPages = [];
  @observable deletedPages = [];
  @observable zoomLevel = DEFAULT_ZOOM_LEVEL;
  @observable containerWidth = 0;
  @observable dropDirection = 'left';

  constructor() {
    makeObservable(this);
  }

  save(transactions, transaction, transactionDocument) {
    return transactions.dispatch(
      transaction.id,
      recombineTransactionDocuments({
        order: this.orderedPages.map((o) => o.id),
        transactionDocumentId: transactionDocument.id,
        transactionDocumentVersionId: transactionDocument.latestVersionId,
      })
    );
  }

  @computed
  get thumbnailDimensions() {
    const { containerWidth, zoomLevel } = this;

    if (containerWidth === 0) {
      return {
        height: 0,
        width: 0,
      };
    }

    const width =
      (containerWidth - TILE_SPACING * 4 - TILE_SPACING * 2 * zoomLevel) /
      zoomLevel;

    return {
      width,
      height: width * 1.25,
    };
  }

  @computed
  get orderedPages() {
    return this.order
      .filter((pageID) => this.deletedPages.indexOf(pageID) === -1)
      .map((id) =>
        find(this.pages, {
          id,
        })
      );
  }

  @action.bound
  moveSelectedPages(targetPageID) {
    const { order, selectedPages, dropDirection } = this;
    const targetPageIndex = order.indexOf(targetPageID);
    const orderJS = order.slice();

    orderJS.splice(
      dropDirection === 'left' ? targetPageIndex : targetPageIndex + 1,
      0,
      selectedPages.slice()
    );

    this.order = flatten(
      orderJS.filter((pageID) => selectedPages.indexOf(pageID) === -1)
    );
  }

  @action.bound
  addPagesToSelection(pageIDs) {
    this.selectedPages = sortPagesInOrder(
      uniq([...this.selectedPages, ...pageIDs]),
      this.order
    );
  }

  @action.bound
  removePagesFromSelection(pageIDs) {
    this.selectedPages = sortPagesInOrder(
      this.selectedPages.filter((pID) => pageIDs.indexOf(pID) === -1),
      this.order
    );
  }

  @action.bound
  clearPageSelection() {
    this.selectedPages = [];
  }

  @action.bound
  setZoomLevel(value) {
    if (value >= MIN_ZOOM_LEVEL && value <= MAX_ZOOM_LEVEL) {
      this.zoomLevel = value;
    }
  }

  @action.bound
  deletePages(pageIDs) {
    this.deletedPages = uniq([...this.deletedPages, ...pageIDs]);

    this.selectedPages = this.selectedPages.filter(
      (pageID) => this.deletedPages.indexOf(pageID) === -1
    );
  }

  @action.bound
  clearDeletedPages() {
    this.deletedPages = [];
  }

  @action.bound
  setDropDirection(direction) {
    this.dropDirection = direction;
  }
}
