import PropTypes from 'prop-types';
import React from 'react';
import api from 'src/api';
import Analytics from 'src/analytics';
import logger from 'src/logger';
import pusher from 'src/utils/pusher';

import { configure } from 'mobx';

// HACK: monkey patch React which no longer ships with proptypes
// eslint-disable-next-line react/no-deprecated
if (!React.PropTypes) {
  // eslint-disable-next-line react/no-deprecated
  React.PropTypes = PropTypes;
}

configure({
  enforceActions: 'observed',
  useProxies: 'never',
});

// // HACK: this monkey patch gets rid of the
// // "Stateless function components cannot be given refs."
// // warning in the antd library.
// class MenuItem extends Component {
//   render() {
//     return <OldMenuItem {...this.props} />;
//   }
// }

// if (!(Menu.Item.prototype && Menu.Item.prototype.render)) {
//   Menu.Item = MenuItem;
// }

export function initGlobals() {
  window.api = api;

  // If user is not logged in on init, browser will refresh after, so there's no need to
  // subscribe anywhere else.
  const SESSION_CHANNEL = window.Glide?.SESSION_CHANNEL;
  if (!window.e2e && SESSION_CHANNEL) {
    const channel = pusher.subscribe(SESSION_CHANNEL);
    channel.bind('did_logout', () => {
      // If session changed since page load, that means this tab is already logged out
      if (SESSION_CHANNEL === window.Glide?.SESSION_CHANNEL) {
        window.location.href = '/auth/login'; //eslint-disable-line
      }
      pusher.unsubscribe(SESSION_CHANNEL);
    });
  }
  window.analytics = {
    track: (name, props, options, callback) => {
      logger.info(
        `App bridge analytics was not initialized. Event received: ${name}`,
        props
      );
      Analytics().track(name, props);
      callback();
    },
  };
}
