import difference from 'lodash/difference';

export const UNKNOWN = 'UNKNOWN';
export const INDIVIDUAL = 'INDIVIDUAL';
export const TRUST = 'TRUST';
export const CORPORATION = 'CORPORATION';
export const LLC = 'LLC';
export const PARTNERSHIP = 'PARTNERSHIP';
export const ESTATE = 'ESTATE';
export const POA = 'POA';
export const OTHER_ENTITY = 'OTHER_ENTITY';

export const ENTITY_TYPES = [
  UNKNOWN,
  INDIVIDUAL,
  TRUST,
  CORPORATION,
  LLC,
  PARTNERSHIP,
  ESTATE,
  POA,
  OTHER_ENTITY,
];

export const LABEL_BY_ENTITY_TYPE = {
  [UNKNOWN]: "Don't know yet",
  [INDIVIDUAL]: 'Individual',
  [TRUST]: 'Trust',
  [CORPORATION]: 'Corporation',
  [LLC]: 'Limited Liability Company',
  [PARTNERSHIP]: 'Partnership',
  [ESTATE]: 'Estate',
  [POA]: 'Power of Attorney',
  [OTHER_ENTITY]: 'Other entity',
};

export const TITLE_PLACEHOLDER_BY_ENTITY_TYPE = {
  [TRUST]: 'ex. Trustee',
  [CORPORATION]: 'ex. Owner',
  [LLC]: 'ex. Owner',
  [PARTNERSHIP]: 'ex. Owner',
  [ESTATE]: 'ex. Administrator',
  [POA]: 'ex. Attorney-in-fact',
  [OTHER_ENTITY]: 'ex. Owner',
};

export const DEFAULT_TITLE_PLACEHOLDER = 'ex. Principal';

export const REQUIRES_ENTITY_NAME_ENTITY_TYPES = [
  TRUST,
  CORPORATION,
  LLC,
  PARTNERSHIP,
  OTHER_ENTITY,
];

export const ENTITY_NAME_PLACEHOLDER_BY_ENTITY_TYPE = {
  [TRUST]: 'ex. John Doe Family Trust',
  [CORPORATION]: 'ex. Acme, Inc.',
  [LLC]: 'ex. Acme, LLC',
  [PARTNERSHIP]: 'ex. Acme Partners',
  [OTHER_ENTITY]: 'ex. Acme Company',
};

export const REPRESENTATIVE_ENTITY_TYPES = difference(ENTITY_TYPES, [
  UNKNOWN,
  INDIVIDUAL,
  POA,
]);

export const LEGAL_NAME_SETTINGS_ENTITY_TYPES = [
  TRUST,
  CORPORATION,
  LLC,
  PARTNERSHIP,
  ESTATE,
  POA,
  OTHER_ENTITY,
];
