import PSPDFKit from 'pspdfkit';

export const PSPDFKIT_URL =
  window.Glide && window.Glide.PSPDFKIT_URL
    ? `${window.Glide.PSPDFKIT_URL}${
        window.Glide.PSPDFKIT_URL.endsWith('/') ? '' : '/'
      }`
    : null;

export const BASE_CONFIG = {
  serverUrl: PSPDFKIT_URL,
  container: '#doc-viewer',
  instant: false,
  autoSaveMode: PSPDFKit.AutoSaveMode.DISABLED,
  editableAnnotationTypes: [PSPDFKit.Annotations.RectangleAnnotation],
  maxDefaultZoomLevel: 2.4,
  minDefaultZoomLevel: 1,
};

export const DEFAULT_ZOOM_LEVEL = 'FIT_TO_WIDTH';

PSPDFKit.Options.IGNORE_DOCUMENT_PERMISSIONS = true;
PSPDFKit.Options.SELECTION_OUTLINE_PADDING = () => -1;
PSPDFKit.Options.SELECTION_STROKE_WIDTH = 2;
export default PSPDFKit;
