import camelCase from 'lodash/camelCase';
import type { CamelCase } from 'type-fest';
import type {
  IntentStepActionKind,
  IntentStepAction,
  StepActionResponse,
  Envelope,
  Intent,
  DispatchResponse,
  ItemKind,
  ItemList,
  Item,
} from 'src/types/proto/signing';
import redirectOnFail from 'src/utils/redirect-on-fail';
import AggregatesApi from './aggregates';

export default class Envelopes extends AggregatesApi<
  Envelope,
  Intent,
  DispatchResponse,
  ItemKind,
  ItemList,
  Item
> {
  prefix = 'envelopes';

  fetchStepViewModel = (
    envelopeId: string,
    stepId: string,
    requireRedirect = true
  ) => {
    const handleStep = async () => {
      const data = await this.dispatchStepAction(
        envelopeId,
        stepId,
        'VIEW',
        {}
      );
      return data?.viewModel;
    };
    if (requireRedirect) {
      return redirectOnFail()(handleStep)();
    }
    return handleStep();
  };

  dispatchStepAction = async (
    envelopeId: string,
    stepId: string,
    kind: IntentStepActionKind,
    payload: IntentStepAction[CamelCase<
      Exclude<IntentStepActionKind, 'UNKNOWN'>
    >]
  ) => {
    const data: IntentStepAction = {
      stepId,
      kind,
      [camelCase(kind)]: payload,
    };
    const r = await this.post<StepActionResponse>(
      `/envelopes/${envelopeId}/step_action`,
      data
    );
    return r.data;
  };
}
